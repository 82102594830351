<template>
    <div class="detail-deposit-offexchange">
        <div class="content">
            <div class="wrap-left d-flex justify-content-between">
                <div>
                    <div class="content-data">
                        <span>Transaction ID</span>
                        <span>{{ getDataDetail.transaction_id }}</span>
                    </div>
                    <div class="content-data">
                        <span>Owner</span>
                        <span>{{ getDataDetail.owner }}</span>
                    </div>
                    <div class="wrap-date">
                        <div class="content-data">
                            <span>Deposit Date</span>
                            <span>{{ getDataDetail.deposit_date | formatDate }}</span>
                        </div>
                        <div class="content-data" v-if="getDataDetail.redeposit_date">
                            <span>Re-Deposit Date</span>
                            <span>{{ getDataDetail.redeposit_date | formatDate }}</span>
                        </div>
                        <div class="content-data">
                            <span>Deposit Due Date</span>
                            <span>{{ getDataDetail.due_date | formatDate }}</span>
                        </div>
                    </div>
                    <div class="content-data">
                        <span>Total Weight</span>
                        <span v-if="getDataDetail.deposit_detail">{{ getDataDetail.deposit_detail.actual_total_weight }}</span>
                    </div>
                </div>
                <div v-if="getDataDetail.payment_info">
                    <div v-if="getDataAttactment.invoice_doc != '' && getDataDetail.status == '005' && getDataDetail.payment_info.payment_status == 'unpaid'">
                        <button type="button" class="btn btn-primer" @click="openModal('modal-add_doc', 'receipt')">Add Proof Payment</button>
                    </div>
                </div>
                <div class="content-data">
                    <span>Status</span>
                    <span v-if="getDataDetail.status == '000'" class="vendor">?</span>
                    <span v-else-if="getDataDetail.status == '001'" class="vendor">New Submission</span>
                    <span v-else-if="getDataDetail.status == '002'" class="abx">Approved By KMI</span>
                    <span v-else-if="getDataDetail.status == '003'" class="operator">Waiting for Deposit</span>
                    <span v-else-if="getDataDetail.status == '004'" class="clearing">Waiting for Test Result</span>
                    <span v-else-if="getDataDetail.status == '005' && getDataDetail.payment_info" class="stored">
                        <span v-if="getDataDetail.payment_info.payment_status == 'unpaid'">Waiting for Payment</span>
                        <span v-else>Stored</span>
                    </span>
                    <span v-else-if="getDataDetail.status == '006'" class="reject">Deposit Suspended</span>
                    <span v-else-if="getDataDetail.status == '007'" class="stored">Waiting for Re-Deposit Confirmation</span>
                    <span v-else-if="getDataDetail.status == '008'" class="stored">Waiting for KMI Confirmation</span>
                    <span v-else-if="getDataDetail.status == '009'" class="stored">Reject</span>
                </div>
            </div>
            <div class="wrap-right">
                <h5>Metal Detail</h5>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Serial Number</th>
                            <th>Fineness</th>
                            <th>Hallmark</th>
                            <th>Weight (gr)</th>
                            <th>Metal Type</th>
                            <th>Print Date</th>
                            <th>Year</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(metal, i) in getMetalInfo" :key="i">
                            <td>{{ metal.serial_number }}</td>
                            <td>{{ metal.fineness }}</td>
                            <td>{{ metal.hallmark }}</td>
                            <td>{{ metal.weight }}</td>
                            <td>{{ metal.metal.type + " " + metal.metal.form }}</td>
                            <td>{{ metal.print_date | formatDate }}</td>
                            <td>{{ metal.year }}</td>
                            <td>
                                <span v-show="metal.status == '001'" class="vendor">Submited</span>
                                <span v-show="metal.status == '002'" class="abx">Approved</span>
                                <span v-show="metal.status == '003'" class="operator">Rejected</span>
                                <span v-show="metal.status == '004'" class="clearing">Stored</span>
                                <span v-show="metal.status == '005'" class="stored">Process</span>
                                <span v-show="metal.status == '006'" class="reject">Withdrawn</span>
                                <span v-show="metal.status == '007'" class="stored">Unsued</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="wrap-right attachment">
                <div class="mb-3">
                    <h5>Attachment</h5>
                </div>
                <!-- <table>
                    <thead>
                        <tr>
                            <th>Application Letter</th>
                            <th>Assignment Letter</th>
                            <th>Deposit Report</th>
                            <th>Analysis Report</th>
                            <th>Invoice</th>
                            <th>Proof Payment</th>
                            <th>Deposit Confirmation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-sm"
                                    v-if="getDataAttactment.application_doc"
                                    @click="openPreview(getDataAttactment.application_doc, 'Application Letter')"
                                >
                                    View
                                </button>
                                <span v-else>Data Not Found</span>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-sm"
                                    v-if="getDataAttactment.assessment_doc"
                                    @click="openPreview(getDataAttactment.assessment_doc, 'Assignment Letter')"
                                >
                                    View
                                </button>
                                <span v-else>Data Not Found</span>
                            </td>
                            <td v-if="getDataAttactment.event_report_doc">
                                <ul v-if="getDataAttactment.event_report_doc.deposit_doc.length != 0">
                                    <li v-for="(docKmi, i) in getDataAttactment.event_report_doc.deposit_doc" :key="i">
                                        <button type="button" class="btn btn-sm" @click="openPreview(docKmi.url, 'Deposit Report')">View</button>
                                    </li>
                                </ul>
                                <span v-else>Data Not Found</span>
                            </td>
                            <td v-if="getDataAttactment.event_report_doc">
                                <ul v-if="getDataAttactment.event_report_doc.analysis_doc.length != 0">
                                    <li v-for="(docKmi, i) in getDataAttactment.event_report_doc.analysis_doc" :key="i">
                                        <button type="button" class="btn btn-sm" @click="openPreview(docKmi.url, 'Analisys Report')">View</button>
                                    </li>
                                </ul>
                                <span v-else>Data Not Found</span>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-sm"
                                    v-if="getDataAttactment.invoice_doc"
                                    @click="openPreview(getDataAttactment.invoice_doc, 'Invoice')"
                                >
                                    View
                                </button>
                                <span v-else>Data Not Found</span>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-sm"
                                    v-if="getDataAttactment.receipt_doc"
                                    @click="openPreview(getDataAttactment.receipt_doc, 'Proof Payment')"
                                >
                                    View
                                </button>
                                <span v-else>Data Not Found</span>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    class="btn btn-sm"
                                    v-if="getDataAttactment.deposit_evidence_doc"
                                    @click="openPreview(getDataAttactment.deposit_evidence_doc, 'Deposit Confirmation')"
                                >
                                    View
                                </button>
                                <span v-else>Data Not Found</span>
                            </td>
                        </tr>
                    </tbody>
                </table> -->
                <b-tabs content-class="mt-3">
                    <b-tab title="Attachment" active>
                        <div class="wrap-attachment">
                            <div class="attachment-item">
                                <div class="attachment-item-title">
                                    <b>Application Letter</b>
                                    <img src="@/assets/icon_cms/icon-check-circle.svg" class="ml-2" alt="" v-if="getDataAttactment.application_doc" />
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-sm"
                                        v-if="getDataAttactment.application_doc"
                                        @click="openPreview(getDataAttactment.application_doc, 'Application Letter')"
                                    >
                                        View
                                    </button>
                                    <span v-else>The file has not been added</span>
                                </div>
                            </div>
                            <div class="attachment-item">
                                <div class="attachment-item-title">
                                    <b>Assignment Letter</b>
                                    <img src="@/assets/icon_cms/icon-check-circle.svg" class="ml-2" alt="" v-if="getDataAttactment.assessment_doc" />
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-sm"
                                        v-if="getDataAttactment.assessment_doc"
                                        @click="openPreview(getDataAttactment.assessment_doc, 'Assignment Letter')"
                                    >
                                        View
                                    </button>
                                    <span v-else>The file has not been added</span>
                                </div>
                            </div>
                            <div class="attachment-item">
                                <div class="attachment-item-title">
                                    <b>Deposit Report</b>
                                    <img
                                        src="@/assets/icon_cms/icon-check-circle.svg"
                                        class="ml-2"
                                        alt=""
                                        v-if="getDataAttactment.event_report_doc.deposit_doc.length != 0"
                                    />
                                </div>
                                <div>
                                    <ul v-if="getDataAttactment.event_report_doc.deposit_doc.length != 0">
                                        <button
                                            type="button"
                                            class="btn btn-sm"
                                            @click="
                                                openPreview(
                                                    getDataAttactment.event_report_doc.deposit_doc[getDataAttactment.event_report_doc.deposit_doc.length - 1].url,
                                                    'Deposit Report'
                                                )
                                            "
                                        >
                                            View
                                        </button>
                                        <!-- <li v-for="(docKmi, i) in getDataAttactment.event_report_doc.deposit_doc" :key="i"></li> -->
                                    </ul>
                                    <span v-else>The file has not been added</span>
                                </div>
                            </div>
                            <div class="attachment-item">
                                <div class="attachment-item-title">
                                    <b>Analysis Report</b>
                                    <img
                                        src="@/assets/icon_cms/icon-check-circle.svg"
                                        class="ml-2"
                                        alt=""
                                        v-if="getDataAttactment.event_report_doc.analysis_doc.length != 0"
                                    />
                                </div>
                                <div>
                                    <ul v-if="getDataAttactment.event_report_doc.analysis_doc.length != 0">
                                        <button
                                            type="button"
                                            class="btn btn-sm"
                                            @click="
                                                openPreview(
                                                    getDataAttactment.event_report_doc.analysis_doc[getDataAttactment.event_report_doc.analysis_doc.length - 1].url,
                                                    'Analysis Report'
                                                )
                                            "
                                        >
                                            View
                                        </button>
                                    </ul>
                                    <span v-else>The file has not been added</span>
                                </div>
                            </div>
                            <div class="attachment-item">
                                <div class="attachment-item-title">
                                    <b>Invoice</b>
                                    <img src="@/assets/icon_cms/icon-check-circle.svg" class="ml-2" alt="" v-if="getDataAttactment.invoice_doc" />
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-sm"
                                        v-if="getDataAttactment.invoice_doc"
                                        @click="openPreview(getDataAttactment.invoice_doc, 'Invoice')"
                                    >
                                        View
                                    </button>
                                    <span v-else>The file has not been added</span>
                                </div>
                            </div>
                            <div class="attachment-item">
                                <div class="attachment-item-title">
                                    <b>Proof Payment</b>
                                    <img src="@/assets/icon_cms/icon-check-circle.svg" class="ml-2" alt="" v-if="getDataAttactment.receipt_doc" />
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-sm"
                                        v-if="getDataAttactment.receipt_doc"
                                        @click="openPreview(getDataAttactment.receipt_doc, 'Proof Payment')"
                                    >
                                        View
                                    </button>
                                    <span v-else>The file has not been added</span>
                                </div>
                            </div>
                            <div class="attachment-item">
                                <div class="attachment-item-title">
                                    <b>Deposit Confirmation</b>
                                    <img src="@/assets/icon_cms/icon-check-circle.svg" class="ml-2" alt="" v-if="getDataAttactment.deposit_evidence_doc" />
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        class="btn btn-sm"
                                        v-if="getDataAttactment.deposit_evidence_doc"
                                        @click="openPreview(getDataAttactment.deposit_evidence_doc, 'Deposit Confirmation')"
                                    >
                                        View
                                    </button>
                                    <span v-else>The file has not been added</span>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="History">
                        <div v-b-toggle.aplicationLetter class="history-title">Application Letter <span class="arrow-down pull-right" /></div>
                        <b-collapse id="aplicationLetter" v-if="getDataAttactment.application_doc">
                            <div class="history-item mb-2">
                                <span @click="openPreview(getDataAttactment.application_doc, 'Application Letter')">{{
                                    getDataAttactment.application_doc.split("/").pop()
                                }}</span>
                                <span></span>
                            </div>
                        </b-collapse>
                        <div v-b-toggle.assignmentLetter class="history-title">Assignment Letter <span class="arrow-down pull-right" /></div>
                        <b-collapse id="assignmentLetter">
                            <div class="history-item mb-2" v-if="getDataAttactment.assessment_doc">
                                <span @click="openPreview(getDataAttactment.assessment_doc, 'Assignment Letter')">{{
                                    getDataAttactment.assessment_doc.split("/").pop()
                                }}</span>
                                <span></span>
                            </div>
                        </b-collapse>
                        <div v-b-toggle.depositReport class="history-title">Deposit Report <span class="arrow-down pull-right" /></div>
                        <b-collapse id="depositReport">
                            <ul v-if="getDataAttactment.event_report_doc.deposit_doc.length != 0">
                                <li v-for="(docKmi, i) in getDataAttactment.event_report_doc.deposit_doc" :key="i" class="history-item">
                                    <span @click="openPreview(docKmi.url, 'Deposit Report ' + [i])">{{ docKmi.url.split("/").pop() }}</span>
                                    <span>{{ docKmi.date | formatDateTimeReal }}</span>
                                </li>
                            </ul>
                        </b-collapse>
                        <div v-b-toggle.analysisReport class="history-title">Analysis Report <span class="arrow-down pull-right" /></div>
                        <b-collapse id="analysisReport">
                            <ul v-if="getDataAttactment.event_report_doc.analysis_doc.length != 0">
                                <li v-for="(docAnalys, i) in getDataAttactment.event_report_doc.analysis_doc" :key="i" class="history-item">
                                    <span @click="openPreview(docAnalys.url, 'Analysis Report ' + [i])">{{ docAnalys.url.split("/").pop() }}</span>
                                    <span>{{ docAnalys.date | formatDateTimeReal }}</span>
                                </li>
                            </ul>
                        </b-collapse>
                        <div v-b-toggle.invoice class="history-title">Invoice <span class="arrow-down pull-right" /></div>
                        <b-collapse id="invoice">
                            <div class="history-item mb-2" v-if="getDataAttactment.invoice_doc">
                                <span @click="openPreview(getDataAttactment.invoice_doc, 'Invoice')">{{ getDataAttactment.invoice_doc.split("/").pop() }}</span>
                                <span></span>
                            </div>
                        </b-collapse>
                        <div v-b-toggle.proofPayment class="history-title">Proof Payment <span class="arrow-down pull-right" /></div>
                        <b-collapse id="proofPayment">
                            <div class="history-item mb-2" v-if="getDataAttactment.receipt_doc">
                                <span @click="openPreview(getDataAttactment.receipt_doc, 'Proof Payment')">{{
                                    getDataAttactment.receipt_doc.split("/").pop()
                                }}</span>
                                <span></span>
                            </div>
                        </b-collapse>
                        <div v-b-toggle.depositConfirmation class="history-title">Deposit Confirmation <span class="arrow-down pull-right" /></div>
                        <b-collapse id="depositConfirmation">
                            <div class="history-item mb-2" v-if="getDataAttactment.deposit_evidence_doc">
                                <span @click="openPreview(getDataAttactment.deposit_evidence_doc, 'Deposit Confirmation')">{{
                                    getDataAttactment.deposit_evidence_doc.split("/").pop()
                                }}</span>
                                <span></span>
                            </div>
                        </b-collapse>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <b-modal ref="preview-modal" size="lg" :title="labelPreview">
            <div class="d-block text-center">
                <div v-if="isPdf">
                    <b-spinner label="Loading..." v-if="isLoadingPreview"></b-spinner>
                    <pdf v-for="i in numPages" :key="i" :src="src" :page="i" v-else />
                </div>
                <div v-else>
                    <b-spinner label="Loading..." v-if="isLoadingPreview"></b-spinner>
                    <img :src="src" alt="" style="width: 100%; object-fit: cover" v-else />
                </div>
            </div>
        </b-modal>
        <b-modal ref="modal-add_doc" title="Upload Document" hide-footer>
            <form action="">
                <div class="form-group">
                    <label for="customs-doc" class="label-form">
                        Upload Document
                        <b class="upperCase">
                            <span v-if="typeDoc == 'evidance'"> Deposit Confirmation </span>
                            <span v-else-if="typeDoc == 'receipt'"> Proof Payment</span>
                            <span v-else>{{ typeDoc }}</span>
                        </b>
                    </label>
                    <label class="upload-video text-center">
                        <span v-if="!isLoadDataCustomDoc"> <b-icon icon="upload" class="mr-1 icon-upload"></b-icon> Choose File </span>
                        <b-spinner small variant="ligth" v-else></b-spinner>
                        <input type="file" accept="image/*, application/pdf" ref="customsDoc" @change="onCustomDoc" />
                    </label>
                    <span id="imageName" class="ml-3"> {{ label_custom }}</span>
                </div>
                <div class="text-right mt-3">
                    <b-button variant="primary" @click="setUpDocument">
                        <b-spinner v-if="isLoadAddDoc" small></b-spinner>
                        <span v-else>Add Document </span>
                    </b-button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import pdf from "vue-pdf";

export default {
    components: { pdf },
    props: {
        dataDetail: Object,
    },
    data() {
        return {
            labelPreview: "",
            isPdf: false,
            isLoadingPreview: false,
            isLoadAddDoc: false,
            numPages: undefined,
            src: null,
            getDetail: [],
            isLoadDataCustomDoc: false,
            label_custom: "",
            documentMetal: "",
            typeDoc: "",
        };
    },
    computed: {
        getDataDetail() {
            return this.dataDetail;
        },
        getMetalInfo() {
            return this.dataDetail.metal_info;
        },
        getDataAttactment() {
            return this.dataDetail.attachments;
        },
        getDataAttactments() {
            let dataMap = {
                application_doc: {
                    value: "",
                    label: "Application Letter",
                },
                assessment_doc: {
                    value: "",
                    label: "Assignment Letter",
                },
                invoice_doc: {
                    value: "",
                    label: "Invoice",
                },
                receipt_doc: {
                    value: "",
                    label: "Proof Payment",
                },
                deposit_evidence_doc: {
                    value: "",
                    label: "Deposit Confirmation",
                },
            };
            Object.keys(dataMap).forEach((data) => {
                dataMap[data].value = this.dataDetail.attachments[data];
                // return this.getDataAttactment[data];
                dataMap["kmi"] = {
                    value: this.dataDetail.attachments["event_report_doc"].kmi,
                    label: "KMI Report",
                };
                dataMap["pegadaian"] = {
                    value: this.dataDetail.attachments["event_report_doc"].pegadaian,
                    label: "Pegadaian Report",
                };
            });
            return dataMap;
        },
    },
    methods: {
        openModal(refModal, type_doc) {
            this.typeDoc = type_doc;
            this.$refs[refModal].show();
        },
        onCustomDoc() {
            this.isLoadDataCustomDoc = true;
            this.label_custom = "";
            const fileCustomDoc = this.$refs.customsDoc.files[0];
            if (
                fileCustomDoc.type == "image/jpeg" ||
                fileCustomDoc.type == "image/jpg" ||
                fileCustomDoc.type == "image/png" ||
                fileCustomDoc.type == "application/pdf"
            ) {
                this.requestFormData(fileCustomDoc).then((res) => {
                    this.isLoadDataCustomDoc = false;
                    this.label_custom = fileCustomDoc.name;
                    this.documentMetal = res.data.data;
                });
            }
        },
        requestFormData(fileData) {
            let formData = new FormData();
            formData.append("file", fileData);
            return axios.post("/v2/upload/external", formData);
        },
        setUpDocument() {
            this.isLoadAddDoc = true;
            let dataDoc = {
                _id: this.$route.params.id,
                doc_type: this.typeDoc,
                doc_url: this.documentMetal,
            };

            axios
                .put("/v2/clearing/admin/abx/offexchange/deposit/update_doc", dataDoc)
                .then((res) => {
                    if (!res.data.iserror) {
                        this.$toast.success("Add Document Success");
                        this.$refs["modal-add_doc"].hide();
                        location.reload();
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                    this.$toast.error("Somthing when wrong");
                    this.isLoadAddDoc = false;
                });
        },
        openPreview(file, label) {
            this.$refs["preview-modal"].show();
            this.labelPreview = label;
            this.isLoadingPreview = true;
            let newFile = file.replace("/kmi", "/vms");
            this.getImage(newFile)
                .then((res) => {
                    if (file.split(".").pop() == "pdf") {
                        this.isPdf = true;
                        this.isLoadingPreview = false;
                        this.src = pdf.createLoadingTask(res);
                        this.src.promise.then((pdf) => {
                            this.numPages = pdf.numPages;
                        });
                    } else {
                        this.isLoadingPreview = false;
                        this.isPdf = false;
                        this.src = res;
                    }
                })
                .catch(() => {
                    this.isLoadingPreview = false;
                });
        },
        async getImage(file) {
            let image = await axios.get("/v2" + file, { responseType: "blob" });
            image = URL.createObjectURL(new Blob([image.data], { type: image.data.type }));
            return image;
        },
    },
};
</script>

<style scoped>
.wrap-left,
.wrap-right {
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 11%);
    padding: 1.5rem;
    border-radius: 5px;
    margin-bottom: 2rem;
}

.content-data {
    margin-bottom: 1rem;
}

.content-data span:first-child {
    display: block;
    width: 8rem;
    font-size: 14px;
}

.content-data span:last-child {
    font-weight: bold;
    display: block;
    font-size: 1rem;
}

.wrap-date {
    display: flex;
    border: solid 1px #cbd2e0;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.wrap-date .content-data {
    margin: unset;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 25px;
    border-radius: 5px;
}

.btn-primer:hover {
    background-color: var(--scondary-color-hover);
}

.upload-video {
    border: 1px solid #1f354a;
    padding: 0.5rem;
    color: #1f354a;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
}

.upload-video:hover {
    background: #1f354a;
    color: #ffffff;
}

.arrow-down {
    border: solid #1f354a;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
}

.icon-upload {
    font-size: 13px;
}

#inputTag {
    display: none;
}

input[type="file"] {
    display: none;
}

.label-form {
    display: block;
}

ul {
    margin-bottom: unset;
}

ul li {
    margin-bottom: 0.5rem;
}

.attachment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-top: 1px solid #cbd2e0;
}

.history-title {
    padding: 1rem 0;
    border-top: 1px solid #cbd2e0;
}

.attachment-item:last-child,
.history-title:last-child {
    border-bottom: 1px solid #cbd2e0;
}

.history-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.history-item span:first-child {
    color: #34aff7;
    cursor: pointer;
}

.history-item span:first-child:hover {
    transform: translateY(-1px);
    color: #33a0df;
}
</style>
